<template>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="text-left">已读政策</span>
        <span style="float: right;" class="text-right">以下为最近15天阅读过的政策</span>
      </div>
      <div v-if="hasData">
        <div v-for="items in readZC"
             :key="''+items.id"
             class="card-item"
             @click="goToZCDetail(items.id, items.type)">
          <div class="left">
            <span>{{items.title}}</span>
            <div class="left-buttom">
              <span>{{items.fwDepartment}}</span>
              <span>上次阅读日期：{{items.createTime}}</span>
            </div>
          </div>
          <div class="right">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="none-data" v-else>
        <img src="@/assets/pc/images/personal/none.png" alt="">
        <span style="font-size: 14px; color: #B0B0B0">暂无内容</span>
      </div>
    </el-card>
</template>

<script>
import { request } from '../../../network';
import { getMemberId } from '@/assets/public/utils/token';
export default {
  data () {
    return {
      readZC: [],
      hasData: true
    };
  },
  created () {
    this.findIsReadProperty();
  },
  mounted () {},
  computed: {
  },
  methods: {
    goToZCDetail (id, type) {
      if (type === 0) {
        this.$router.push({
          path: '/formaldetails',
          query: {
            id
          }
        });
      } else if (type === 1) {
        this.$router.push({
          path: '/faguidetails',
          query: {
            id
          }
        });
      }
    },
    async findIsReadProperty () {
      if (getMemberId() !== '') {
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/PersonalCenter/findIsReadZC',
          params: {
            memberId: getMemberId()
          }
        });
        if (res.code !== 200) return this.$message.error('数据获取失败');
        if (res.data.length === 0) {
          this.hasData = false;
          return;
        }
        const newArr = [];
        const arrReverse = res.data;
        arrReverse.forEach(item1 => {
          if (newArr.length === 0) {
            newArr.push(item1);
          } else {
            for (let i = 0; i < newArr.length; i++) {
              if (newArr[i].id !== item1.id) {
                if (i === newArr.length - 1) {
                  newArr.push(item1);
                } else {
                  continue;
                }
              } else {
                break;
              }
            }
          }
        });
        this.readZC = newArr;
      }
    }
  }
};
</script>

<style lang="less" scoped>
 /deep/.el-card__body {
  padding: 5px 30px 25px;
   height: auto;
   overflow: auto;
 }
 .text-left {
   font-size: 16px;
   font-weight: bold;
   color: #000000;
 }
 .text-right {
   font-size: 14px;
   color: #9E9E9E;
 }
 .card-item {
   display: flex;
   justify-content: space-between;
   position: relative;
   box-sizing: border-box;
   height: 100px;
   border-bottom: 1px solid #D4D6DA;
   cursor: pointer;
 }
 .card-item .left{
   position: absolute;
   top: 25px;
   width: 776px;
   overflow:hidden;
   text-overflow:ellipsis;
   white-space:nowrap;
   font-size: 18px;
 }
 .card-item .left .left-buttom{
   font-size: 14px;
   color: #B8B8B8;
 }
 .card-item .right{
   position: absolute;
   top: 50%;
   right: 0;
   transform: translateY(-50%);
 }
 .card-item .left .left-buttom {
  margin-top: 20px;
   display: flex;
 }
 .card-item .left .left-buttom span:nth-child(2){
   margin-left: 20px;
 }
 /deep/.el-icon-arrow-right:before {
   width: 8px;
   height: 14px;
 }
 /*滚动条样式设置*/
 /deep/.el-card__body::-webkit-scrollbar {
   width: 6px;
   height: 400px;
 }
 /deep/.el-card__body::-webkit-scrollbar-thumb {
   border-radius: 3px;
   background-color: #ECECEC;
 }
 .none-data {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 340px;
 }
</style>
